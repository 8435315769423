









































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsSidebarLayout from '@/components/VsSidebarLayout/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsCustomFieldsModal from '@/modules/lists/components/VsCustomFieldsModal/Index.vue'
import VsContactSupportModal from '@/modules/lists/components/VsContactSupportModal/Index.vue'
import VsCustomFieldCard from '@/modules/lists/components/VsCustomFieldCard/Index.vue'
import {
    CustomFieldTypeEnum,
    CustomField,
} from '@/utils/customFields'
import {
    getList,
    getListCustomFields,
} from '@/api/consoleApi/recipients'
import {
    createCustomField,
    updateCustomField,
    deleteCustomField,
} from '@/api/consoleApi/customFields'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import VsFormSection from '@/components/VsFormSection/Index.vue'
import axios from 'axios'
import { UserModule } from '@/store/modules/user'
import VsSubscriberSidebar from '@/modules/lists/components/VsSubscriberSidebar/Index.vue'
import { get } from 'lodash'

@Component({
    name: 'ListCustomFields',
    components: {
        VsSidebarLayout,
        VsCustomFieldsModal,
        VsSectionHeader,
        VsContactSupportModal,
        VsCustomFieldCard,
        VsConfirm,
        VsFormSection,
        VsSubscriberSidebar,
    },
})
export default class extends Vue {
    private list: any = null
    private customFields: CustomField[] = []
    private customFieldsNotUnique: CustomField[] = []
    private pagination = {
        totalItems: 0,
        currentPage: 1,
        itemsPerPage: 20,
    }

    private filters = {
        type: [],
        required: '',
        name: '',
    }

    $refs: any

    beforeMount () {
        this.getList()
        this.getListCustomFields()
    }

    get user () {
        return UserModule.user
    }

    get listId () {
        return this.$route?.params?.listId
    }

    private async getList () {
        try {
            const resp = await getList(this.listId)
            this.list = resp.data.data
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 404) {
                    this.$root.$vsToast({
                        timeout: 3000,
                        heading: 'Lista non trovata',
                        aspect: VsToastAspectEnum.alert,
                    })
                    this.$router.replace({ name: 'listsIndex' })
                    return
                }
            }
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore nel reperire i dati della lista, riprova più tardi',
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
    }

    private async getListCustomFields () {
        try {
            this.customFields = await this.customFieldsLoop(1)
        } catch (e) {
            this.customFields = []
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore nel reperire i dati dei campi personalizzati, riprova più tardi',
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
    }

    private async customFieldsLoop (page: number): Promise<CustomField[]> {
        const resp = await getListCustomFields(this.listId, { page })
        const customFields: CustomField[] = resp.data.data
        let fields: CustomField[] = []
        if (
            resp.data.meta.pagination.current_page < resp.data.meta.pagination.total_pages &&
            resp.data.meta.pagination.current_page === page
        ) {
            page = page + 1
            fields = await this.customFieldsLoop(page)
        }
        return [
            ...customFields,
            ...fields,
        ]
    }

    private openCustomFieldModal (customField?: CustomField) {
        this.$refs.vsCustomFieldsModal.openModal(
            {
                unique: false,
                showMobile: !customField && !this.customFields.find(el => el.type === CustomFieldTypeEnum.mobile),
                showEmail: !customField && !this.customFields.find(el => el.type === CustomFieldTypeEnum.email),
            },
            customField,
        )
    }

    private async createCustomField (customField: any) {
        this.$refs.vsCustomFieldsModal.loading = true
        try {
            customField.recipient_id = this.listId
            await createCustomField(customField)
            await this.getListCustomFields()
            await this.getCustomFields()
            this.$emit('customfields-change')
            this.$refs.vsCustomFieldsModal.loading = false
            this.$refs.vsCustomFieldsModal.closeModal()
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Campo personalizzato creato con successo',
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            console.log(e)
            let message = ''
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 403) {
                    this.$root.$vsToast({
                        timeout: 3000,
                        heading: 'Hai raggiunto il limite di campi personalizzati creabili per il tuo piano',
                        aspect: VsToastAspectEnum.alert,
                    })
                    this.$refs.vsCustomFieldsModal.loading = false
                    return
                }

                const placeholderError = get(e, 'response.data.message.placeholder', false)
                if (placeholderError) {
                    message = 'Il placeholder inserito non è valido, prova con un altro valore'
                }
            }
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore durante la creazione del campo personalizzato',
                message,
                aspect: VsToastAspectEnum.alert,
            })
            this.$refs.vsCustomFieldsModal.loading = false
        }
    }

    private async updateCustomField (event: any) {
        this.$refs.vsCustomFieldsModal.loading = true
        try {
            event.data.recipient_id = this.listId
            await updateCustomField(event.id, event.data)
            await this.getListCustomFields()
            await this.getCustomFields()
            this.$refs.vsCustomFieldsModal.loading = false
            this.$refs.vsCustomFieldsModal.closeModal()
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Campo personalizzato modificato con successo',
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            console.log(e)
            let message = ''
            const placeholderError = get(e, 'response.data.message.placeholder', false)
            if (placeholderError) {
                message = 'Il placeholder inserito non è valido, prova con un altro valore'
            }
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore durante la modifica del campo personalizzato',
                message,
                aspect: VsToastAspectEnum.alert,
            })
            this.$refs.vsCustomFieldsModal.loading = false
        }
    }

    private async deleteCustomField (customFieldId: any) {
        try {
            await this.$refs.vsConfirm.openConfirm()
        } catch (e) {
            return
        }
        try {
            await deleteCustomField(customFieldId)
            await this.getListCustomFields()
            await this.getCustomFields()
            this.$emit('customfields-change')
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Campo personalizzato eliminato con successo',
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore durante la cancellazione del campo personalizzato',
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
    }

    @Watch('filters', { immediate: false, deep: true })
    @Watch('pagination', { immediate: true, deep: true })
    private async getCustomFields () {
        const resp = await getListCustomFields(this.listId, {
            page: this.pagination.currentPage,
            limit: this.pagination.itemsPerPage,
            searchFields: `unique${this.filters.name ? ';name:like' : ''}`,
            search: `unique:No${this.filters.name ? ';name:' + this.filters.name : ''}`,
            searchJoin: 'and',
        })
        this.pagination.totalItems = resp.data.meta.pagination.total
        this.customFieldsNotUnique = resp.data.data
    }

    private openSupportModal () {
        this.$refs.vsContactSupportModal.openModal()
    }
}
