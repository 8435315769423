




























import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
    name: 'VsCheckList',
})
export default class extends Vue {
    @Prop({ required: true, default: () => [] }) list!: string[]
}
