





















import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsContactSupportModal',
})
export default class extends Vue {
    private open = false

    private openSupport () {
        this.$emit('open-support')
        this.open = false
    }

    public openModal () {
        this.open = true
    }

    public closeModal () {
        this.open = false
    }
}
