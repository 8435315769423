























































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsCheckList from '@/components/VsCheckList/Index.vue'

@Component({
    name: 'VsSubscriberSidebar',
    components: {
        VsCheckList,
    },
})
export default class extends Vue {
    @Prop({ default: () => [], required: true }) customFields!: any[]
    @Prop({ default: '', required: false }) listId!: any

    get uniqueFields () {
        return this.customFields.filter(el => el.validation.unique)
    }

    get notUniqueFields () {
        return this.customFields.filter(el => !el.validation.unique)
    }
}
